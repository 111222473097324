import React, {Fragment, Suspense} from 'react';
import {Layout, Menu, Breadcrumb, Button, Dropdown, Modal, message, Tabs} from 'antd';
import AsyncComponent from './AsyncComponent.jsx';
import CustomModal from '../common/Modal';
import Helper from "@/class/Helper";
import {Router, Switch, Route} from "react-router-dom";
import Loading from "@/component/Loading";
// import RoleList from './Basic/RoleList';  //角色列表
// import AdminList from './Basic/AdminList';  //管理员列表
// import OperationLog from './Basic/OperationLog';  //操作日志
// import MenuSet from './Set/MenuSet';  //菜单设置
// import UploadSet from './Set/UploadSet';  //上传设置
// import BasicInfo from './Set/BasicInfo';  //基本信息设置配置

// import SetColor from './SetColor';  //主题
// import EditPwd from './EditPwd';  //修改密码
// import UserInfo from './UserInfo';  //个人信息

const RoleList = AsyncComponent(() => import('./Basic/RoleList'));  //角色列表
const AdminList = AsyncComponent(() => import('./Basic/AdminList'));  //管理员列表
const OperationLog = AsyncComponent(() => import('./Basic/OperationLog'));  //操作日志
const MenuSet = AsyncComponent(() => import('./Set/MenuSet'));  //菜单设置
const UploadSet = AsyncComponent(() => import('./Set/UploadSet'));  //上传设置
const BasicInfo = AsyncComponent(() => import('./Set/BasicInfo'));  //基本信息设置配置
const SetColor = AsyncComponent(() => import('./SetColor'));  //主题
const EditPwd = AsyncComponent(() => import('./EditPwd'));  //修改密码
const UserInfo = AsyncComponent(() => import('./UserInfo'));  //个人信息
const Withdraw = AsyncComponent(() => import('./Set/Withdraw/index'))
const Tip = AsyncComponent(() => import('./Configuration/Tip/index'))
const Profession = AsyncComponent(() => import('./Configuration/Profession/index'))
const SerialNumber = AsyncComponent(() => import('./Configuration/SerialNumber/index'))
const Question = AsyncComponent(() => import('./Configuration/Question/index'))
const Commodity = AsyncComponent(() => import('./Commodity/index'))
const CemeteryList = AsyncComponent(() => import('./Cemetery/CemeteryList/index'))
const CemeteryArea = AsyncComponent(() => import('./Cemetery/CemeteryArea/index'))
const Golgotha = AsyncComponent(() => import('./Cemetery/Golgotha/index'))
const CemeteryTopUp = AsyncComponent(() => import('./Cemetery/CemeteryTopUp/index'))
const CemeteryWithdraw = AsyncComponent(() => import('./Cemetery/CemeteryWithdraw/index'))
const FundingDetails = AsyncComponent(() => import('./Cemetery/FundingDetails/index'))
const UserList = AsyncComponent(() => import('./User/UserList/index'))
const UserMessage = AsyncComponent(() => import('./User/UserMessage/index'))
const CodeList = AsyncComponent(() => import('./Code/CodeList/index'))
const Distribute = AsyncComponent(() => import('./Code/Distribute/index'))
const Sequence = AsyncComponent(() => import('./Sequence/index'))
const SequenceOrder = AsyncComponent(() => import('./Order/SequenceOrder/index'))
const GoodsOrder = AsyncComponent(() => import('./Order/GoodsOrder/index'))
const Machine = AsyncComponent(() => import('./Machine/index'))
const Banner = AsyncComponent(() => import('./Parameter/Banner/index'))
const Info = AsyncComponent(() => import('./Parameter/Info/index'))
const AccountWithdrawal = AsyncComponent(() => import('./Parameter/AccountWithdrawal/index'))
const Reminder = AsyncComponent(() => import('./Parameter/Reminder/index'))
const Sacrifice = AsyncComponent(() => import('./Parameter/Sacrifice/index'))
const IncomeExpenditure = AsyncComponent(() => import('./Funds/IncomeExpenditure/index'))
const RechargeDetails = AsyncComponent(() => import('./Funds/RechargeDetails/index'))
const WithdrawalsRecord = AsyncComponent(() => import('./Funds/WithdrawalsRecord/index'))
const Equipment = AsyncComponent(() => import('./CemeteryMachine/Equipment/index'))
const Reserve = AsyncComponent(() => import('./CemeteryMachine/Reserve/index'))
const Video = AsyncComponent(() => import('./CemeteryMachine/Video/index'))
const Area = AsyncComponent(() => import('./Graveyard/Area/index'))
const Grave = AsyncComponent(() => import('./Graveyard/Grave/index'))
const ShortMessage = AsyncComponent(() => import('./Graveyard/ShortMessage/index'))
const CemeteryCodeList = AsyncComponent(() => import('./CemeteryCode/CemeteryCodeList/index'))
const CemeteryDistribute = AsyncComponent(() => import('./CemeteryCode/CemeteryDistribute/index'))
const CemeterySequence = AsyncComponent(() => import('./CemeterySequence/index'))
const Cs = AsyncComponent(() => import('./Cs/index'))
const OfferingsList = AsyncComponent(() => import('./Offerings/OfferingsList/index'))
const OfferingsOrder = AsyncComponent(() => import('./Offerings/OfferingsOrder/index'))

const {Header, Content, Footer, Sider} = Layout;
const {SubMenu} = Menu;
const {TabPane} = Tabs;
const Components = {
    Cs,
    OfferingsList,
    OfferingsOrder,
    CemeterySequence,
    CemeteryDistribute,
    CemeteryCodeList,
    ShortMessage,
    Grave,
    Area,
    Video,
    Reserve,
    Equipment,
    WithdrawalsRecord,
    RechargeDetails,
    IncomeExpenditure,
    RoleList,
    AdminList,
    OperationLog,
    MenuSet,
    UploadSet,
    BasicInfo,
    Withdraw,
    Tip,
    Profession,
    SerialNumber,
    Question,
    Commodity,
    CemeteryList,
    CemeteryArea,
    Golgotha,
    CemeteryTopUp,
    CemeteryWithdraw,
    FundingDetails,
    UserList,
    UserMessage,
    CodeList,
    Distribute,
    Sequence,
    SequenceOrder,
    GoodsOrder,
    Machine,
    Banner,
    Info,
    AccountWithdrawal,
    Reminder,
    Sacrifice
}

export default class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            collapsed: false,
            menu: [],
            headerIcon: 'icon-jibenguanli',
            headerFirstName: '基本管理',
            headerSecName: '角色列表',
            visible: false,
            pwdVisible: false,
            infoVisible: false,
            systemName: "鸿鹄科技管理后台",
            username: "",
            avatar: "",
            selectKey: '',
            openKey: '',
            reqFinished: false,
            tabs: [],
            path: ''
        }
        this.tabRef = [];
    }

    componentDidMount() {
        this.getUserInfo();
        setTimeout(() => {
            window.delDom()
        }, 2000);
    }

    toggle = () => {
        var zancOpenKey = '', openKey = '';
        let collapsed = !this.state.collapsed;
        if (collapsed) {  //
            zancOpenKey = this.state.openKey;
            openKey = ''
        } else {
            openKey = this.state.zancOpenKey;
            zancOpenKey = ''
        }
        this.setState({
            collapsed: !this.state.collapsed,
            openKey,
            zancOpenKey,
        });
    };

    changeVisible(key) {
        this.setState({
            [key]: true,
        })
    }

    getUserInfo() {
        global.Ajax.hhtc_request('config/settingById', {id: 5},).then(res => {
            // setNum(res.data.value)
            // console.log(res)
            this.setState({
                logo: res.data.value
            })
        })
        global.Ajax.hhtc_request("admin/getLoginInfo", {}).then(data => {
            if (data.code === 1) {
                document.title = data.data.name;
                var menu = data.data.menus;

                // let menu = [];
                // menu = menu.concat([{ id: 26, path: 'adminlist', title: '测试菜单', icon: 'icon-yonghu', child: [] }])
                var tabs = [], activeKey = '', selectKey, openKey, path;
                if (menu.length > 0) {
                    if (menu[0].child.length > 0 && menu[0].path == '') {
                        path = (menu[0].child[0].path.split('/'))[menu[0].child[0].path.split('/').length - 1];
                        tabs = [{
                            title: menu[0].child[0].title,
                            component: path.substr(0, 1).toUpperCase() + path.substr(1),
                            key: 'tab' + menu[0].child[0].id,
                            subKey: 'sub' + menu[0].id,
                            menuKey: menu[0].child[0].id,
                            label: menu[0].child[0].title,
                            closable: false

                        }]
                    } else {
                        path = (menu[0].path.split('/'))[menu[0].path.split('/').length - 1];
                        tabs = [{
                            title: menu[0].title,
                            component: path.substr(0, 1).toUpperCase() + path.substr(1),
                            key: 'tab' + menu[0].id,
                            subKey: 'sub' + menu[0].id,
                            menuKey: menu[0].id,
                            label: menu[0].child[0].title,
                            // key: String(menu[0].child[0].id),
                            // path: menu[0].child[0].path,
                            closable: false
                        }]
                    }

                    activeKey = tabs[0].key;
                    selectKey = tabs[0].menuKey;
                    openKey = tabs[0].subKey;
                    // console.log(tabs, 'tabs')
                    console.log(path, 'init')
                    this.setState({
                        systemName: data.data.name,
                        username: data.data.username,
                        avatar: data.data.avatar,
                        menu,
                        tabs,
                        activeKey,
                        selectKey,
                        openKey,
                        reqFinished: true,
                        path,
                        refresh: true
                    })
                }


            }
        })
    }

    handMenu(e) {
        // console.log(e)
        let openKey = this.state.openKey,
            zancOpenKey = this.state.openKey;
        if (this.state.collapsed || e.keyPath.length == 1) {
            openKey = '';
        }
        this.setState({
            selectKey: e.key,
            zancOpenKey,
            openKey,
        })
    }

    handTitle(e) {
        let openKey = ''
        if (this.state.openKey != e.key) {
            openKey = e.key;
        }
        // console.log(openKey)

        this.setState({
            openKey
        })
    }

    // 刷新
    onRefresh = () => {
        // console.log(this.tabRef[this.state.selectKey].state)
        if (this.tabRef[this.state.selectKey]) {
            this.tabRef[this.state.selectKey].refresh()
        }
        message.success('刷新成功')
    }
    list = (path, id) => {
        var MyComponentt = Components[path];

        if (!MyComponentt) {
            console.log(this.state)
            console.log(path, id)
        }
        return <MyComponentt _ref={dom => this.tabRef[id] = dom} routePush={this.routePush.bind(this)}
                             isSetAccountWithdrawal={this.isSetAccountWithdrawal.bind(this)}/>;
    }

    render() {
        const {collapsed} = this.state;
        const menu = (
            <Menu>
                <Menu.Item key="1" onClick={this.changeVisible.bind(this, 'pwdVisible')}>修改密码</Menu.Item>
                <Menu.Item key="2" onClick={this.changeVisible.bind(this, 'infoVisible')}>个人信息</Menu.Item>
                <Menu.Item key="3" onClick={() => {
                    window.reset()
                    localStorage.removeItem('honghuToken')
                    message.success("再见", 1, () => {
                        window.location.href = ''
                    })
                }}>退出登录</Menu.Item>
            </Menu>
        )

        return (
            <Layout style={{minHeight: '100vh', _height: '100vh'}}>
                <Sider trigger={null} theme='light' width={240} collapsible collapsed={collapsed}
                       onCollapse={this.toggle} className='leftMenu'>
                    <div className="logo">

                        {
                            Helper.conditionShow(this.state.logo, (
                                <div className='logo-box img-box'>
                                    <img src={this.state.logo}/>
                                </div>
                            ), (
                                <p>{this.state.systemName.substr(0, 2)}</p>
                            ))
                        }
                        <h1>{this.state.systemName}</h1>
                    </div>
                    {this.state.reqFinished &&
                        <Menu openKeys={[this.state.openKey]} selectedKeys={[String(this.state.selectKey)]}
                              mode="inline" onClick={this.handMenu.bind(this)}>
                            {this.state.menu.map((item, index) => {
                                // console.log(item)

                                return (
                                    <React.Fragment key={'' + index}>
                                        {(item.child.length > 0 && item.path == '') ?
                                            <SubMenu key={`sub${item.id}`}
                                                     icon={<p className={`iconfont ${item.icon}`}></p>}
                                                     title={item.title} onTitleClick={this.handTitle.bind(this)}>
                                                {item.child.map((row, k) => (
                                                    <Menu.Item key={String(row.id)}
                                                               onClick={this.add.bind(this, row, item)}>{row.title}</Menu.Item>
                                                ))}
                                            </SubMenu>
                                            :
                                            <Menu.Item className='firr' key={String(item.id)}
                                                       icon={<p className={`iconfont ${item.icon}`}></p>}
                                                       onClick={this.add.bind(this, item, item)}>{item.title}</Menu.Item>
                                        }
                                    </React.Fragment>
                                )
                            })}
                        </Menu>}
                </Sider>
                <Layout className="site-layout">
                    <Header>
                        <div className='topnav'>
                            <p className={`iconfont ${this.state.collapsed ? 'icon-zhankai' : 'icon-shouqi'} leftMenuToggle`}
                               onClick={this.toggle.bind(this)}></p>
                            <p className={`iconfont icon-shuaxin leftMenuToggle`} onClick={this.onRefresh}></p>
                            <div className='zhut flexCenter' onClick={this.changeVisible.bind(this, 'visible')}>
                                <p className='iconfont icon-zhuti'></p>
                                <p>主题</p>
                            </div>
                            {(this.state.avatar == "" || !this.state.avatar) ?
                                <img alt='' src={require('../imgs/default.png').default} className='avatar'/>
                                :
                                <img alt='' src={this.state.avatar} className='avatar'/>
                            }
                            <Dropdown placement='bottomCenter' overlay={menu}>
                                <div className='flexCenter'>
                                    <p>{this.state.username}</p>
                                    <span className='iconfont icon-jiantou-shang'></span>
                                </div>
                            </Dropdown>
                        </div>
                    </Header>
                    <Content>
                        {this.state.reqFinished && this.state.refresh && (
                            <Fragment>
                                <Tabs
                                    type="editable-card"
                                    hideAdd={true}
                                    items={this.state.tabs}
                                    activeKey={this.state.activeKey || 'tab0'}
                                    className='asdTabs'
                                    onChange={this.onChange}
                                    onEdit={this.onEdit}
                                />
                                {/*{this.state.tabs.map(item => (*/}
                                {/*    <TabPane closable={this.state.tabs.length == 1 ? false : true}*/}
                                {/*             closeIcon={(<p className='iconfont icon-guanbi closeTab'></p>)}*/}
                                {/*             tab={item.title} key={item.key}>*/}
                                {/*        {list(item.component, item.menuKey)}*/}
                                {/*    </TabPane>*/}
                                {/*))}*/}
                                <Suspense fallback={<Loading/>}>
                                    {this.state.path != '' && this.list(this.state.path, this.state.activeKey)}
                                </Suspense>
                            </Fragment>
                        )}

                        {/*<Router>*/}
                        {/*    <Switch>*/}
                        {/*        <Route path="/rout/rolelist" component={RoleList}/>*/}
                        {/*        <Route path="/rout/adminlist" component={AdminList}/>*/}
                        {/*        <Route path="/rout/operationlog" component={OperationLog}/>*/}
                        {/*        <Route path="/rout/menuset" component={MenuSet}/>*/}
                        {/*        <Route path="/rout/uploadset" component={UploadSet}/>*/}
                        {/*        <Route path="/rout/basicinfo" component={BasicInfo}/>*/}
                        {/*    </Switch>*/}
                        {/*</Router>*/}
                        {/*{*/}
                        {/*    this.state.reqFinished && (*/}
                        {/*        <Fragment>*/}
                        {/*            <Tabs*/}
                        {/*                className='asdTabs'*/}
                        {/*                items={this.state.tabs}*/}
                        {/*                type="editable-card"*/}
                        {/*                hideAdd*/}
                        {/*                activeKey={this.state.activeKey}*/}
                        {/*                onEdit={this.onEdit}*/}
                        {/*                onChange={this.onChange}*/}
                        {/*            />*/}
                        {/*            <Suspense fallback={<Loading/>}>*/}
                        {/*                {this.state.path != '' && list(this.state.path, this.state.activeKey)}*/}
                        {/*            </Suspense>*/}
                        {/*        </Fragment>*/}
                        {/*    )*/}
                        {/*}*/}

                    </Content>
                </Layout>
                {/* 修改主题 */}
                <CustomModal
                    visible={this.state.visible}
                    width={1172}
                    title='主题配色'
                    onCancel={this.onCancel.bind(this)}
                >
                    <SetColor ref={dom => this.setcolor = dom} onCancel={this.onCancel.bind(this)}
                              onOk={this.onOk.bind(this)}/>
                </CustomModal>
                {/* 修改密码 */}
                <CustomModal
                    visible={this.state.pwdVisible}
                    width={360}
                    title='修改密码'
                    onCancel={this.onCancel.bind(this)}
                >
                    <EditPwd ref={dom => this.editpwd = dom} onCancel={this.onCancel.bind(this)}/>
                </CustomModal>
                {/* 个人信息 */}
                <CustomModal
                    visible={this.state.infoVisible}
                    width={360}
                    title='个人信息'
                    onCancel={this.onCancel.bind(this)}
                >
                    <UserInfo
                        ref={dom => this.userinfo = dom}
                        avatar={this.state.avatar}
                        username={this.state.username}
                        onCancel={this.onCancel.bind(this)}
                        onRefresh={this.getUserInfo.bind(this)}
                    />
                </CustomModal>
            </Layout>
        )
    }


    async isSetAccountWithdrawal() {
        const res = await global.Ajax.cemeteryReq('cemeteryport/cemetery/cemeteryById', {id: global.cemeteryId()})
        if (res.code) {
            if (!res.data.bank_no) {
                message.error('请设设置提现账户')
                this.routePush('AccountWithdrawal')
                return true
            }
        }
    }

    routePush(path) {
        const menus = Helper.flat(this.state.menu, 'child')
        const menu = menus.find(e => e.path === path)
        const pMenu = menus.find(e => e.id === menu.pid)
        if (menu) {
            const tabPath = this.state.tabs.find(e => e.component === menu.path)
            if (tabPath) {
                this.onChange('tab' + menu.id)
            } else {
                this.add(menu, pMenu)
                const selectKey = this.state.tabs[this.state.tabs.length - 1].menuKey;
                const openKey = this.state.tabs[this.state.tabs.length - 1].subKey;
                this.setState({
                    activeKey: 'tab' + menu.id,
                    selectKey,
                    openKey,
                    zancOpenKey: openKey,
                })
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.path !== prevState.path) {
            // 当 state 中的 data 发生变化时执行的逻辑
            console.log('path 发生了变化:', this.state.path);
        }
    }

    add(row, itemm) {
        var {tabs} = this.state;
        var rowItem = tabs.find(item => item.title == row.title);
        console.log(row, itemm)
        // console.log(rowItem)
        if (!rowItem) {
            this.setState({
                refresh: false
            })
            var path = (row.path.split('/'))[row.path.split('/').length - 1];
            // console.log(path)
            var obj = {
                title: row.title,
                component: path.substr(0, 1).toUpperCase() + path.substr(1),
                key: 'tab' + row.id,
                subKey: 'sub' + itemm.id,
                menuKey: row.id,
                label: row.title,
            }
            tabs.push(obj);
            this.setState({
                tabs,
                activeKey: obj.key,
            }, () => {
                // console.log(this.state)
                this.setState({
                    refresh: true
                })
            })
        } else {
            this.setState({
                activeKey: rowItem.key
            })
        }
        if (row.path != '') {
            console.log(row.path)
            // this.props.history.push(row.path)
            this.setState({
                headerIcon: itemm.icon,
                headerFirstName: itemm.title,
                headerSecName: row.title,
                path: row.path
            })
        }
    }

    onEdit = (targetKey, action) => {
        // console.log(action, targetKey)
        this[action](targetKey)
    }
    remove = targetKey => {
        const {tabs, activeKey} = this.state;
        let newActiveKey = activeKey, selectKey = '', openKey = '', path;
        let lastIndex;
        tabs.forEach((pane, i) => {
            if (pane.key === targetKey) {
                lastIndex = i - 1;
            }
        });
        const newPanes = tabs.filter(pane => pane.key !== targetKey);
        if (newPanes.length && newActiveKey === targetKey) {
            if (lastIndex >= 0) {
                newActiveKey = newPanes[lastIndex].key;
                selectKey = newPanes[lastIndex].menuKey;
                openKey = newPanes[lastIndex].subKey;
                path = newPanes[lastIndex].component
            } else {
                newActiveKey = newPanes[0].key;
            }
        } else {
            newActiveKey = newPanes[newPanes.length - 1].key;
            selectKey = newPanes[newPanes.length - 1].menuKey;
            openKey = newPanes[newPanes.length - 1].subKey;
            path = newPanes[newPanes.length - 1].component

        }
        let zancOpenKey = openKey;
        if (!this.state.collapsed) {
            // openKey=openKey;
        } else {
            openKey = '';
        }
        console.log(newPanes)
        this.setState({
            tabs: newPanes,
            activeKey: newActiveKey,
            openKey,
            selectKey,
            zancOpenKey,
            path
        });
    }
    onChange = (activeKey) => {
        const {tabs} = this.state;
        var row = tabs.find(item => item.key == activeKey);
        // console.log(activeKey, row)

        let openKey = '',
            zancOpenKey = row.subKey;
        if (!this.state.collapsed) {
            openKey = row.subKey;
        }
        // console.log(row)
        this.setState({
            activeKey,
            selectKey: row.menuKey,
            openKey,
            zancOpenKey,
            path: row.component
        })
    }

    onOk() {
        this.onCancel()
    }

    onCancel() {
        this.setState({
            visible: false,
            pwdVisible: false,
            infoVisible: false,
        })
    }
}
