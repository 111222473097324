import {message} from 'antd';
import fetch from "isomorphic-fetch";

export default class Ajax {
    hhtc_request(url, data) {
        if (url.includes('cemeteryport')) {
            data = {
                cemetery_id: Number(global.cemeteryId()) ? Number(global.cemeteryId()) : undefined,
                ...data
            }
        }
        if (url.includes('editCemeteryList')) {
            data = {
                id: Number(global.cemeteryId()) ? Number(global.cemeteryId()) : undefined,
                ...data
            }
        }
        var requrl = global.httpUrl + url;
        var init = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json;',
                "Accept": "application/json",
                'token': localStorage.getItem('honghuToken') || ""
            }),
            body: JSON.stringify(data)
        }
        return fetch(requrl, init).then(
            (response) => {
                return response.json()
            }
        ).then(
            (data) => {
                if (data.code === 999) {
                    // return
                    window.reset()
                    if (localStorage.getItem('honghuToken')) {
                        localStorage.removeItem('honghuToken')

                    }
                    message.error(data.msg, 1, () => {
                        window.location.href = ''
                    })
                    return data;
                }
                return data;
            }
        ).catch(
            (err) => {
                console.log("执行一次网络请求:请求地址=》", requrl, "返回结果=>网络异常", err)
                var data = {
                    code: 0,
                    msg: '网络异常!'
                }
                return data;
            }
        )
    }

    cemeteryReq(url, data) {
        data = {
            cemetery_id: Number(global.cemeteryId()) ? Number(global.cemeteryId()) : undefined,
            ...data
        }
        if (url.includes('editCemeteryList')) {
            data = {
                id: Number(global.cemeteryId()) ? Number(global.cemeteryId()) : undefined,
                ...data
            }
        }
        var requrl = global.httpUrl + url;
        var init = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json;',
                "Accept": "application/json",
                'token': localStorage.getItem('honghuToken') || ""
            }),
            body: JSON.stringify(data)
        }
        return fetch(requrl, init).then(
            (response) => {
                return response.json()
            }
        ).then(
            (data) => {
                if (data.code === 999) {
                    // return
                    window.reset()
                    if (localStorage.getItem('honghuToken')) {
                        localStorage.removeItem('honghuToken')

                    }
                    message.error(data.msg, 1, () => {
                        window.location.href = ''
                    })
                    return data;
                }
                return data;
            }
        ).catch(
            (err) => {
                console.log("执行一次网络请求:请求地址=》", requrl, "返回结果=>网络异常", err)
                var data = {
                    code: 0,
                    msg: '网络异常!'
                }
                return data;
            }
        )
        // return  this.hhtc_request(url,{
        //     cemetery_id: global.cemeteryId(),
        //     ...data
        // })
    }
}
