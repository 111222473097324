import React from 'react';
import {Spin} from 'antd';

const Index = () => (
    <div className='' style={{textAlign: 'center', paddingTop: '20vh'}}>
        <Spin/>
        <p style={{fontSize: 14, color: '#666', marginTop: 10,}}>加载中...</p>
    </div>
);

export default Index;
