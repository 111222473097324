import React from 'react';
import ReactDOM from 'react-dom';
import 'core-js/es'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import 'antd/dist/antd.less';
import './index.less';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ConfigProvider} from 'antd';
import 'moment/locale/zh-cn';
import locale from 'antd/lib/locale/zh_CN';
// import LOG from './class/LOG'

// LOG.init()
// window.LOG = LOG
// global.httpUrl = "http://192.168.0.87/";  //java本地
// global.httpUrl="https://layer.honghukeji.net/admin/";  //php线上
// global.httpUrl = "http://mb.letiankj.cn/";  //java本地
// global.httpUrl = "https://932qygg95476.vicp.fun/";  //java本地
// global.httpUrl = "/";  //java本地


// global.httpUrl = "https://mudi.ooxl.cn/";  //java本地
global.httpUrl = "http://localhost:8002/";  //java本地

if (process.env.NODE_ENV !== 'development') {
    global.httpUrl = "https://mudi.ooxl.cn/";  //线上打包地址
}
// global.httpUrl = "https://18869e3h55.goho.co/";  //java本地
global.imgUrl = 'https://wzimg.honghukeji.net/'//图片上传域名
global.cemeteryId = () => {
    return localStorage.getItem('cemeteryId')
}
// global.LOG = LOG
ReactDOM.render(
    <ConfigProvider locale={locale}>
        <App/>
    </ConfigProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// reportWebVitals(console.log);
