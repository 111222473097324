export default class Validate {

    static isBoolean(com_params) {
        return typeof com_params === 'boolean'
    }

    static isArray(com_params) {
        // console.log(typeof com_params === 'object');
        // typeof com_params === 'object' && com_params.constructor === Array
        return Array.isArray(com_params)
    }

    static isObject(com_params) {
        return typeof com_params === 'object' && com_params.constructor === Object
    }


    static isUndefined(com_params) {
        return typeof com_params === 'undefined'
    }

    static isString(com_params) {
        return typeof com_params === 'string'
    }

    static isEmptyObject(com_params) {

        return typeof com_params === 'object' && Object.keys(com_params).length === 0;
    }

    static isFunction(com_params) {
        return typeof com_params === "function"
    }

    static byTypeGetResult(params) {
        for (const key in params) {
            if (key !== 'value') {
                if (this[key](params.value)) {
                    return params[key]
                }
            }
        }
    }
}
