import * as XLSX from 'xlsx'
import {Fragment} from "react";
import {message} from "antd";
import Validate from "@/class/Validate";

export default class Helper {
    static registerComponent(key, component) {
        Helper[key] = component()
        console.log(Helper[key])
    }

    static useHelperComponent(key) {
        // if(!Helper[key]){
        // 	console.error('组件不存在')
        // 	return
        // }
        return (
            <Fragment>
                {Helper[key]}
            </Fragment>
        )
    }

    static getNum(index, total, size, page, orderBy) {

        var num = index + 1;
        if (orderBy == "desc") {
            num = total - (page - 1) * size - (num - 1);
        } else {
            num = (page - 1) * size + num
        }
        if (num < 10) {
            num = '0' + num;
        }
        return num
    }

    static xlsxExport(data, columns, name) {
        let xlsxData = []
        if (data.length > 0) {
            data.forEach((item, index) => {
                let newItem = {}
                newItem['序号'] = index + 1
                for (const itemKey in item) {
                    if (columns.find(e => e.key === itemKey)) {

                        newItem[columns.find(e => e.key === itemKey).title] = item[itemKey]
                    }
                }
                xlsxData.push(newItem)
            })
        }
        // 创建sheet对象
        let sheet = XLSX.utils.json_to_sheet(xlsxData)
        // 创建一个工作薄
        let wb = XLSX.utils.book_new()
        // 将sheet对象放入到工作薄
        XLSX.utils.book_append_sheet(wb, sheet, 'Sheet1')
        // 导出Excel文件
        XLSX.writeFile(wb, name + '.xlsx')
    }

    static mapArraySomeKey(data, key) {
        return data.some(item => Object.keys(item).includes(key));
    }

    static getMapArrayAllKey(data) {
        const allKeys = new Set();
        data.forEach(item => {
            Object.keys(item).forEach(key => {
                allKeys.add(key);
            });
        });
        return Array.from(allKeys)
    }

    static guid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    static isParsableJSON(str) {
        try {
            JSON.parse(str);
            return true;
        } catch (error) {
            return false;
        }
    }

    static jsonParse(data) {
        return Helper.isParsableJSON(data) ? JSON.parse(data) : ''
    }

    static arrToMapRemove(data, map_key, value) {
        if (!value) {
            throw Error('value 不能为空')
        }
        const map = new Map()
        const arr = []
        data.forEach((e, k) => {
            map.set(e[map_key], e)
        })
        map.delete(value)
        Array.from(map).forEach((e, k) => {
            arr.push(e[1])
        })
        return arr
    }

    static recursionSetField(data, fields, childrenKey) {
        for (let i = 0; i < data.length; i++) {
            fields.forEach(item => {
                // 0new 1old
                data[i][item[0]] = data[i][item[1]]
            })
            if (data[i][childrenKey]) {
                Helper.recursionSetField(data[i][childrenKey], fields, childrenKey)
            }
        }
        return data
    }

    static conditionShow(condition, trueCom, falseCom) {
        return condition ? trueCom : falseCom
    }

    static valueToLabel(data, value) {
        return data.find(item => item.value === value).label
    }

    static labelToValue(data, label) {
        return data.find(item => item.label === label).value
    }

    static flat(data, arrKey = 'children') {
        let arr = JSON.parse(JSON.stringify(data))

        let key = 0
        let len = arr.length
        while (key < len) {
            if (arr[key][arrKey]) {
                arr = [...arr, ...arr[key][arrKey]]
                delete arr[key][arrKey]
                len = arr.length
            }
            len = arr.length
            key++
        }
        return arr
    }

    static notBind(data, table) {
        global.Ajax.hhtc_request('cemeteryport/qrcode/qrcodeUnbind', data).then(res => {
            if (res.code == 1) {
                message.success(res.msg, 1.2)
                if (table.onRefresh) {
                    table.onRefresh()
                } else {
                    table.current.onRefresh()
                }
            } else {
                message.error(res.msg, 1.2)
            }
        })
    }

    static arrRemoveItem(arr, item) {
        if (arr.length) {
            const index = arr.indexOf(item)
            if (index > -1) {
                return arr.splice(index, 1)
            }
        }
        if (arr.length === 1) {
            return []
        }
    }

    static checkFieldConsistency(arr, fieldName) {
        // 获取第一个对象的字段值作为基准
        const baseValue = arr[0][fieldName];
        // 遍历数组，判断每个对象的字段值是否与基准值一致
        for (let i = 1; i < arr.length; i++) {
            if (arr[i][fieldName] !== baseValue) {
                return false; // 如果不一致，返回 false
            }
        }
        return true; // 如果所有对象的字段值都一致，返回 true
    }

    static getRandomString(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    static str2fileList(str) {
        if (!str) {
            return []
        }
        var imgs = str.split(",");
        var fileList = [];
        for (var i = 0; i < imgs.length; i++) {
            fileList.push({
                uid: this.getRandomString(12),
                name: this.getRandomString(6),
                url: imgs[i],
                thumbUrl: imgs[i],
                status: "done"
            })
        }
        return fileList;
    }

    static fileList2str(fileList) {
        var imgs = [];
        for (var i = 0; i < fileList.length; i++) {
            imgs.push(fileList[i].url)
        }
        return imgs.join(",")
    }
}
